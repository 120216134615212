import colors from '../utils/colors';
import Highcharts from "./highcharts_config";

const numberOfClusterZoneRanges = 20;
const maxMarkSize = 25;
const minMarkSize = 7;
const minOfClusterGroupsSize = 5;

const parseData = (data) => {
  const scoresCounts = [];
  const series = Object.keys(data).map((facetIndex) => {
    return Object.entries(data[facetIndex]).map(([score, scoreCount]) => {
      scoresCounts.push(scoreCount);
      return {
        type: 'scatter',
        data: Array(scoreCount).fill([parseInt(score), parseInt(facetIndex)]),
        color: setColor(score)
      };
    });
  }).flat();

  const limits = {
    max: Math.max(...scoresCounts),
    min: Math.min(...scoresCounts)
  };

  return { series, limits };
};

const setColor = (score) => ({
  '-5': colors.bgRose400,
  '-4': colors.bgRose400,
  '-3': colors.bgRose400,
  '-2': colors.bgOrange400,
  '-1': colors.bgOrange400,
  '0': colors.bgSky300,
  '1': colors.bgSky300,
  '2': colors.bgSky300,
  '3': colors.bgEmerald300,
  '4': colors.bgEmerald300,
  '5': colors.bgEmerald300
})[score.toString()];

const segmentsZones = (limits) => {
  const deltaSize = (maxMarkSize - minMarkSize) / numberOfClusterZoneRanges
  const maxLimit = Math.max(limits.max, minOfClusterGroupsSize*limits.min)
  const blockSize = (maxLimit - limits.min) / numberOfClusterZoneRanges;
  const intervals = Array.from({ length: numberOfClusterZoneRanges }, (_, i) => {
    const start = limits.min + i * blockSize;
    const end = start + blockSize;
    return {
      from: start,
      to: end,
      marker: {
        symbol: 'circle',
        radius: minMarkSize + (i + 1) * deltaSize
      }
    };
  });
  return intervals;
};

export const highChartsConfig = (locationName, scoresData, reporting) => {
  const facetsName = Object.keys(scoresData);
  const peopleReportingLabel = reporting;
  const parsedData = parseData(Object.values(scoresData));
  const zones = segmentsZones(parsedData.limits);
  const xAxisLabelBackgroundColors = {
    '-5': colors.bgRose50,
    '-4': colors.bgRose50,
    '-3': colors.bgRose50,
    '-2': colors.bgOrange50,
    '-1': colors.bgOrange50,
    '0': colors.bgSky50,
    '1': colors.bgSky50,
    '2': colors.bgSky50,
    '3': colors.bgEmerald50,
    '4': colors.bgEmerald50,
    '5': colors.bgEmerald50
  };
  const xAxisLabelTextColors = {
    '-5': colors.textRose900,
    '-4': colors.textRose900,
    '-3': colors.textRose900,
    '-2': colors.textOrange800,
    '-1': colors.textOrange800,
    '0': colors.textSky800,
    '1': colors.textSky800,
    '2': colors.textSky800,
    '3': colors.textEmerald800,
    '4': colors.textEmerald800,
    '5': colors.textEmerald800
  };

  // Plot lines for each of the facets
  const yAxisPlotLines = facetsName.map((_facet, index) => ({
    value: index,
    color: '#F3F4F6',
    width: 1,
    zIndex: 1
  }));

  return {
    chart: {
      zoomType: 'xy',
      plotBorderColor: '#D1D5DB',
      plotBorderWidth: 1,
      spacing: [0,1,1,0],
      height: facetsName.length * 75, // Adjust height based on number of rows
      events: {
        load: function () {
          const chart = this;
          const xAxis = chart.xAxis[0];
          const ranges = [
            { from: -5.5, to: -2.5, color: xAxisLabelBackgroundColors[-5] },
            { from: -2.5, to: -0.5, color: xAxisLabelBackgroundColors[-1] },
            { from: -0.5, to: 2.5, color: xAxisLabelBackgroundColors[0] },
            { from: 2.5, to: 5.5, color: xAxisLabelBackgroundColors[3] }
          ];

          const drawBackgrounds = () => {
            // Remove existing custom rectangles
            if (chart.customBackgrounds) {
              chart.customBackgrounds.forEach(bg => bg.destroy());
            }
            chart.customBackgrounds = [];
            // Add custom rectangles behind x-axis labels
            ranges.forEach(range => {
              const fromPos = xAxis.toPixels(range.from);
              const toPos = xAxis.toPixels(range.to);

              const rect = chart.renderer
                .rect(
                  fromPos,                                 // x-coordinate
                  chart.plotHeight + chart.plotTop + 15,   // y-coordinate (below chart area)
                  toPos - fromPos,                         // width
                  chart.marginBottom                       // height (adjust to fit labels)
                )
                .attr({
                  fill: range.color,
                  zIndex: 0                                // Ensure it is behind the labels
                })
                .add();
              chart.customBackgrounds.push(rect);
            });
          };
          // Draw the initial background
          drawBackgrounds();

          // Redraw backgrounds on chart resize
          Highcharts.addEvent(chart, 'redraw', drawBackgrounds);
        }
      }
    },
    series: parsedData.series,
    title: {
      text: locationName,
      align: 'left',
      margin: 10,
      style: {
        fontWeight: 'bold'
      }
    },
    xAxis: {
      min: -5.5,
      max: 5.5,
      gridLineWidth: 1,
      tickInterval: 1,
      tickWidth: 0,
      opposite: false,
      gridLineColor: '#F3F4F6',
      lineWidth: 0,
      labels: {
        useHTML: true,
        style: {
          color: colors.textGray500,
          fontSize: '12px'
        },
        rotation: 0,
        formatter: function () {
          const value = this.value.toString();
          const textColor = xAxisLabelTextColors[value] || '#4B5563';
          const style = `display: inline-flex; align-items: center; color: ${textColor}; padding: 4px 12px; margin: 0;`;
          return `<span style="${style}">${value}</span>`;
        }
      }
    },
    yAxis: {
      categories: facetsName,
      gridLineWidth: 0, // Hide grid lines
      title: {
        text: peopleReportingLabel,
        align: 'low',
        useHTML: true,
        rotation: 0,
        reserveSpace: false,
        y: facetsName.length * 7,
        style: {
          color: colors.textGray500,
          fontSize: '12px',
          fontWeight: 500
        },
      },
      labels: {
        useHTML: true,
        style: {
          color: colors.textGray500,
          fontSize: '12px',
          fontWeight: 500
        },
        formatter: function () {
          const facetName = this.value;
          const parameterizedFacetName = facetName.toLowerCase().replace(/ /g, '-');
          const svgImage = `<img src="/team_climate/icon-email-wc-${parameterizedFacetName}-16.png" class="inline h-4 w-4"/>`;

          return `${svgImage} <span class="mr-4">${facetName}</span>`;
        }
      },
      plotLines: yAxisPlotLines
    },
    legend: {
      enabled: false
    },
    tooltip: {
      backgroundColor: "#000",
      borderColor: "#000",
      borderRadius: 15,
      style: {
        color: "#fff",
      },
      formatter: function () {
        return `<b>Score:</b> ${Math.round(this.point.x)}
        <br>
        <b>People:</b> ${this.series.xData.length}`
      }
    },
    plotOptions: {
      series: {
        marker: {
          symbol: 'circle',
          radius: minMarkSize
        },
        cluster: {
          enabled: true,
          zones: zones,
          minimumClusterSize: 0,
          drillToCluster: false,
          allowOverlap: false,
          marker: {
            lineWidth: 1
          },
          layoutAlgorithm: {
            type: 'grid',
            gridSize: 1
          },
        }
      }
    }
  }
};
