import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="range-slider"
export default class extends Controller {
  static targets = [ 'slider' ]
  static values = { start: String, middle: String, end: String }
  static outlets = ['reveal']

  connect() {
    this.updateThumbColor();
    this.revealOnInputChange();
  }

  updateThumbColor() {
    // Run once on page load
    this.setThumbColor(this.sliderTarget.value);

    // Update thumb color on input change
    this.sliderTarget.addEventListener('input', (event) => {
      this.setThumbColor(event.target.value);
    });
  }

  setThumbColor(value) {
    let normalizedValue = (value - this.sliderTarget.min) / (this.sliderTarget.max - this.sliderTarget.min);

    // Parse the RGB values from the start, middle, and end color strings
    let startColor = this.startValue.split(',').map(Number);
    let middleColor = this.middleValue.split(',').map(Number);
    let endColor = this.endValue.split(',').map(Number);

    // Interpolate between the colors of the gradient
    let red, green, blue;
    if (normalizedValue < 0.5) {
      // Interpolate between start and middle
      red = Math.round((middleColor[0] - startColor[0]) * normalizedValue * 2 + startColor[0]);
      green = Math.round((middleColor[1] - startColor[1]) * normalizedValue * 2 + startColor[1]);
      blue = Math.round((middleColor[2] - startColor[2]) * normalizedValue * 2 + startColor[2]);
    } else {
      // Interpolate between middle and end
      red = Math.round((endColor[0] - middleColor[0]) * (normalizedValue - 0.5) * 2 + middleColor[0]);
      green = Math.round((endColor[1] - middleColor[1]) * (normalizedValue - 0.5) * 2 + middleColor[1]);
      blue = Math.round((endColor[2] - middleColor[2]) * (normalizedValue - 0.5) * 2 + middleColor[2]);
    }

    let color = `rgb(${red}, ${green}, ${blue})`;

    this.sliderTarget.style.setProperty('--thumb-color', color);
  }

  revealOnInputChange() {
    const toggleReveal = () => {
      if (this.hasRevealOutlet) {
        this.revealOutlets.forEach(reveal => {
          reveal.toggle();
        });
      }
      this.sliderTarget.removeEventListener('input', toggleReveal);
    };
  
    this.sliderTarget.addEventListener('input', toggleReveal);
  }
}
