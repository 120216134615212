import { Controller } from "@hotwired/stimulus";

// A Stimulus controller to asynchronously load HTML from an url.
// Connects to data-controller="content-loader"
export default class ContentLoader extends Controller {
  static values = {
    url: String,
    lazyLoading: Boolean,
    lazyLoadingThreshold: Number,
    lazyLoadingRootMargin: {
      type: String,
      default: "0px",
    }
  };

  connect() {
    if (!this.hasUrlValue) {
      console.error("[stimulus-content-loader] You need to pass an url to fetch the remote content.");
      return;
    }

    this.hasLazyLoadingValue ? this.lazyLoad() : this.load();
  }

  load() {
    this.fetch();
  }

  lazyLoad() {
    const options = {
      threshold: this.lazyLoadingThresholdValue,
      rootMargin: this.lazyLoadingRootMarginValue,
    };

    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.load();
          this.observer.disconnect();
        }
      });
    }, options);

    this.observer.observe(this.element);
  }

  fetch() {
    fetch(this.urlValue)
      .then((response) => response.text())
      .then((html) => {
        this.element.innerHTML = html;
      })
      .catch((error) => console.error("[stimulus-content-loader] Error fetching content:", error));
  }
}
