import Highcharts from "./highcharts_config";

document.addEventListener("turbolinks:load", () => {
  const chartContainer = document.getElementById('journal-ratio-chart');
  if (!chartContainer) return;

  const timestamps = JSON.parse(chartContainer.dataset.chartDataX);
  const minTimestamp = parseInt(chartContainer.dataset.minTimestamp, 10) || Date.now() - (365 * 24 * 60 * 60 * 1000);
  const journalEntries = JSON.parse(chartContainer.dataset.chartDataJournalEntries);
  const submissions = JSON.parse(chartContainer.dataset.chartDataSubmissions);
  const journalRatios = JSON.parse(chartContainer.dataset.chartDataRatios).map((ratios) => ratios[0]);
  const submissionRatios = JSON.parse(chartContainer.dataset.chartDataRatios).map((ratios) => ratios[1]);
  const stars = JSON.parse(chartContainer.dataset.chartDataStars);
  const serieJournalRatios = {
    name: 'Climate Journal Entries',
    type: 'column',
    data: journalRatios.map((ratio, index) => [timestamps[index], ratio]),
    color: '#34D399',
    borderColor: 'rgba(34,135,98,0.8)',
    topBar: true,
    animation: false
  }
  const serieSubmissionRatios = {
    name: 'Climate Submissions',
    type: 'column',
    data: submissionRatios.map((ratio, index) => [timestamps[index], ratio]),
    color: '#E0718D',
    borderColor: 'rbga(156,78,98,0.65)',
    animation: false
  }
  // Increase the title margin to make room for the stars
  // 65px => 3 stars, 45px => 2 stars, 30px => 1 star, 25px => 0 stars
  const titleMargin = function () {
    if (stars.includes(3)) {
      return 65;
    } else if (stars.includes(2)) {
      return 45;
    } else if (stars.includes(1)) {
      return 30;
    } else {
      return 25;
    }
  }
  const options = {
    credits: { enabled: false },
    chart: {
      type: 'column',
      events: {
        render: function () {
          const chart = this;
          const serie = chart.series.filter((serie) => serie.name === 'Climate Journal Entries')[0];
          serie.points.forEach(function (point) {
            if (!point.topBar) {
              // Create the top bar when it does not exist.
              point.topBar = chart.renderer.rect(
                chart.plotLeft + point.barX - 2,
                chart.plotTop + point.plotY,
                point.pointWidth + 4,
                2.5
              ).attr({
                fill: 'rgb(36,95,70)',
                zIndex: 3
              }).add();
            } else if (serie.visible) {
              // Redraw the top bar make the serie visible again.
              point.topBar.attr({
                x: chart.plotLeft + point.barX - 2,
                y: chart.plotTop + point.plotY,
                width: point.pointWidth + 4,
              });
            } else {
              // Hide the top bar when the serie is hidden.
              point.topBar.attr({
                width: 0,
              });
            }
          });
        }
      }
    },
    tooltip: {
      valueDecimals: 2,
      shared: true,
      outside: true,
      formatter: function () {
        const dateTimeLabel = Highcharts.dateFormat('Week of %b %d %Y', this.x);
        const weekRatio = journalRatios[timestamps.indexOf(this.x)];
        const weekJournalEntries = journalEntries[timestamps.indexOf(this.x)];
        const weekSubmissions = submissions[timestamps.indexOf(this.x)];
        return `<span style="font-size: 10px;">${dateTimeLabel}</span><br>` +
          `<span>Ratio: <span style="font-weight: bold">${weekRatio.toFixed(2)}</span></span></br>` +
          `<span>Submissions: <span style="font-weight: bold">${weekSubmissions}</span></span></br>` +
          `<span>Journal Entries: <span style="font-weight: bold">${weekJournalEntries}</span></span></br>`

      }
    },
    title: {
      text: 'Journal Ratio',
      align: 'left',
      margin: titleMargin()
    },
    xAxis: {
      type: 'datetime',
      gridLineWidth: 1,
      gridLineColor: 'rgb(244, 245, 246)',
      min: minTimestamp,
    },
    yAxis: {
      title: '',
      min: 0.0,
      max: 1.0,
      tickInterval: 0.5,
      gridLineColor: 'rgb(244, 245, 246)',
      stackLabels: {
        enabled: true,
        useHTML: true,
        crop: false,
        overflow: 'none',
        formatter: function () {
          const starsOnPoint = stars[timestamps.indexOf(this.x)];
          if (starsOnPoint === 0) return;

          let starSVG = `<svg xmlns="http://www.w3.org/2000/svg" height="11" viewBox="0 0 12 11" width="12"><path d="m5.76732944 9.12232215-2.39730488 1.26033775c-.24442229.1285004-.54673587.0345272-.67523627-.2098951-.05116954-.0973303-.06882687-.20881432-.05023845-.31719313l.45784449-2.66943821c.02781897-.16219703-.02595528-.32769713-.14379832-.44256571l-1.93946038-1.89050663c-.19774179-.19275061-.20178794-.50930724-.00903734-.70704903.0767543-.07874182.17732551-.12998541.28614402-.14579766l2.68026833-.38946579c.16285509-.02366423.30363788-.12594891.37646888-.27352065l1.19865244-2.42873511c.12221115-.24762683.42202407-.34929633.6696509-.22708519.0986063.04866512.17842006.12847888.22708518.22708519l1.19865244 2.42873511c.072831.14757174.21361379.24985642.37646888.27352065l2.68026834.38946579c.2732724.03970881.4626131.29343023.4229043.56670266-.0158122.10881852-.0670558.20938972-.1457976.28614403l-1.93946041 1.89050663c-.11784304.11486858-.17161729.28036868-.14379832.44256571l.45784449 2.66943821c.04668051.27216823-.13611341.53064623-.40828163.57732673-.10837881.0185884-.21986285.000931-.31719309-.0502385l-2.39730488-1.26033775c-.14566201-.07657905-.31967911-.07657905-.46534112 0z" fill="#fcd34d" fill-rule="evenodd"/></svg>`;
          if(starsOnPoint === 3) {
            starSVG = `<svg xmlns="http://www.w3.org/2000/svg" height="11" viewBox="0 0 12 11" width="12"><path d="m5.76732944 9.12232215-2.39730488 1.26033775c-.24442229.1285004-.54673587.0345272-.67523627-.2098951-.05116954-.0973303-.06882687-.20881432-.05023845-.31719313l.45784449-2.66943821c.02781897-.16219703-.02595528-.32769713-.14379832-.44256571l-1.93946038-1.89050663c-.19774179-.19275061-.20178794-.50930724-.00903734-.70704903.0767543-.07874182.17732551-.12998541.28614402-.14579766l2.68026833-.38946579c.16285509-.02366423.30363788-.12594891.37646888-.27352065l1.19865244-2.42873511c.12221115-.24762683.42202407-.34929633.6696509-.22708519.0986063.04866512.17842006.12847888.22708518.22708519l1.19865244 2.42873511c.072831.14757174.21361379.24985642.37646888.27352065l2.68026834.38946579c.2732724.03970881.4626131.29343023.4229043.56670266-.0158122.10881852-.0670558.20938972-.1457976.28614403l-1.93946041 1.89050663c-.11784304.11486858-.17161729.28036868-.14379832.44256571l.45784449 2.66943821c.04668051.27216823-.13611341.53064623-.40828163.57732673-.10837881.0185884-.21986285.000931-.31719309-.0502385l-2.39730488-1.26033775c-.14566201-.07657905-.31967911-.07657905-.46534112 0z" fill="#fcd34d" fill-rule="evenodd" stroke="#F59E0B" stroke-width="1"/></svg>`;
          }
          // Create an array of stars, then join them with a <br/> tag to stack them vertically
          const starsHTML = Array.from({length: starsOnPoint}, () => starSVG).map((star) => `<div class="mb-1">${star}</div>`).join('');
          return '<div>' + starsHTML + '</div>';
        }
      }
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        events: {
          legendItemClick: function (e) {
            e.preventDefault();
          }
        }
      },
      column: {
        borderWidth: 1,
        borderRadius: 0,
        dataLabels: {
          enabled: false
        },
        maxPointWidth: 15
      }
    },
    legend: {enabled: true, align: 'left'},
    series: [serieSubmissionRatios, serieJournalRatios]
  }

  Highcharts.chart(chartContainer.id, options)
});
