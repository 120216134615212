document.addEventListener('turbolinks:load', () => {
  setupFacetFeedbackActions();
  setupQuickFacetSubmissionActions();
});

const setupFacetFeedbackActions = () => {
  const sliders = document.querySelectorAll('.wcs-range-slider');

  if (!sliders) return;

  sliders.forEach((slider) => {
    const handleSliderChange = (event) => {
      const sliderValue = event.target.value;
      const textField = document.getElementById(`text-field-container-${slider.id}`);
      const facetDetails = document.getElementById(`facet-details-${slider.id}`);

      if (sliderValue < 0) {
        if (!textField.classList.contains('invisible')) return;
        textField.classList.remove('opacity-0', 'invisible', 'pointer-events-none', 'h-0');
        textField.classList.add('opacity-100', 'h-full');
        textField.focus();

        facetDetails.classList.remove('opacity-100', 'flex-grow');
        facetDetails.classList.add('opacity-0', 'invisible', 'h-0');

        setTimeout(() => {
          facetDetails.classList.add('invisible');
        }, 500); // Match the duration of the fade-out transition
      } else {
        if (!facetDetails.classList.contains('invisible')) return;

        textField.blur();
        textField.classList.remove('opacity-100', 'h-full');
        textField.classList.add('opacity-0', 'pointer-events-none', 'h-0');
        
        setTimeout(() => {
          textField.classList.add('invisible');
          facetDetails.classList.remove('opacity-0', 'invisible', 'h-0');
          facetDetails.classList.add('opacity-100', 'flex-grow');
        }, 500); // Match the duration of the fade-out transition
      }
    };

    slider.addEventListener('change', handleSliderChange);
  });
}

const setupQuickFacetSubmissionActions = () => {
  const modal = document.getElementById('climateModal');
  const triggers = document.querySelectorAll('#climateStats, #statsTap');

  triggers.forEach(trigger => {
    trigger?.addEventListener('click', (event) => {
      event.stopPropagation(); // Prevents the click from reaching document
      openModal();
    });
  });

  if (modal) openModal();

  const sliders = document.querySelectorAll('.climate-range-slider');
  if (!sliders) return;

  sliders.forEach((slider) => {
    slider.addEventListener('input', () => {
      toggleCommentField(slider);
    });

    // Allow skipping the comment field
    const sliderContainer = slider.closest('.slider-container');
    const commentField = sliderContainer.querySelector(".climate-comment-field");
    const leaveCommentLink = sliderContainer.querySelector('.leave-comment-link');
    const skipLink = commentField.querySelector('#skipComment');

    skipLink.addEventListener('click', () => {
      hideCommentField(sliderContainer);
      if (slider.value < 0) {
        showLeaveCommentLink(sliderContainer);
      } else {
        hideLeaveCommentLink(sliderContainer);
      }
    });
    leaveCommentLink.addEventListener('click', () => {
      showCommentField(sliderContainer);
      hideLeaveCommentLink(sliderContainer);
    });

    // When reflecting negative value on initial load, display the leave comment link
    if (slider.value < 0) showLeaveCommentLink(sliderContainer);
  });

  const submitButton = document.getElementById('climateSubmitButton');
  const intro = document.querySelector('.climate-intro');
  const outro = document.querySelector('.climate-outro');
  const statsOutro = document.querySelector('.stats-outro');
  const audio = document.getElementById('sent');
  audio.volume = 0.4;

  submitButton.addEventListener('click', () => {
    audio.play();
    closeModal();
    intro.classList.add('hidden');
    outro.classList.remove('hidden');
    statsOutro.classList.remove('hidden');
  });
};

// Define animation classes
const sliderDelays = [
  "delay-[100ms]", "delay-[200ms]", "delay-[300ms]", "delay-[400ms]",
  "delay-[150ms]", "delay-[75ms]", "delay-[100ms]", "delay-[200ms]"
];
const modalEntering = ["opacity-100", "pointer-events-auto"];
const modalLeaving = ["opacity-0", "scale-y-75", "pointer-events-none"];
const statsEntering = ["opacity-100", "scale-y-100", "translate-y-0", "pointer-events-auto"];
const statsLeaving = ["opacity-0", "scale-y-[200%]", "translate-y-0", "pointer-events-none"];
const sliderHidden = ["opacity-30", "scale-50", "translate-y-2"];
const sliderVisible = ["opacity-100", "scale-100", "translate-y-0"];
const originalBodyOverflow = document.body.style.overflow;

// Helper function to toggle classes
const toggleClasses = (element, addClasses, removeClasses) => {
  element.classList.add(...addClasses);
  element.classList.remove(...removeClasses);
};

const openModal = () => {
  const modal = document.getElementById("climateModal");
  const stats = document.getElementById("climateStats");
  const sliders = modal?.querySelectorAll(".slider-container");

  toggleClasses(modal, modalEntering, modalLeaving);
  toggleClasses(stats, statsLeaving, statsEntering);

  sliders.forEach((slider, index) => {
    toggleClasses(slider, [...sliderVisible, sliderDelays[index]], sliderHidden);
  });

  // Prevent page scroll when modal is open.
  document.body.style.overflow = "hidden";
};

const closeModal = () => {
  const modal = document.getElementById("climateModal");
  const stats = document.getElementById("climateStats");
  const sliders = modal?.querySelectorAll(".slider-container");

  toggleClasses(modal, modalLeaving, modalEntering);
  toggleClasses(stats, statsEntering, statsLeaving);

  sliders.forEach((slider, index) => {
    toggleClasses(slider, sliderHidden, [...sliderVisible, sliderDelays[index]]);

    // Update stat indicators
    const value = parseInt(slider.querySelector(".climate-range-slider").value, 10);
    const name = slider.querySelector(".climate-range-slider").name;
    updateStatIndicator(name, value);
  });

  // Restore scrolling
  document.body.style.overflow = originalBodyOverflow;
};

const updateStatIndicator = (name, value) => {
  const stats = document.getElementById("climateStats");
  const facetName = name.match(/\[(.*?)\]/)[1].toLowerCase().replace(" ", "_");

  const indicator = stats.querySelector(`.${facetName} > .stat-indicator`);
  const leftFade = stats.querySelector(`.${facetName} > .stat-fades > .left-fade`);
  const rightFade = stats.querySelector(`.${facetName} > .stat-fades > .right-fade`);

  if (!indicator) return; // Prevent errors if element isn't found

  const parentWidth = indicator.parentElement.offsetWidth;
  const halfWidth = (indicator.offsetWidth / 2) / parentWidth;

  // Normalize value from -50 to 50 → 0 to 100
  let left = ((value + 50) / 100) * 100 - (halfWidth * 100);

  // Clamp values between 0 and 100
  left = Math.max(0, Math.min(left, 100 - (halfWidth * 2 * 100)));

  indicator.style.left = `${left}%`;
  indicator.style.background = colorForValue(value);
  leftFade.style.right = `${100 - left}%`;
  rightFade.style.left = `${left}%`;
};

const interpolateColor = (start, end, factor) => 
  start.map((channel, i) => Math.round(channel + (end[i] - channel) * factor));

const colorForValue = (value) => {
  const startRGB = [252, 26, 26];   // Red (-50)
  const middleRGB = [80, 96, 238];  // Blue (0)
  const endRGB = [18, 206, 25];     // Green (50)

  // Normalize value from -50 to 50 → 0 to 1
  let normalizedValue = Math.max(0, Math.min((value + 50) / 100, 1));

  const rgb = normalizedValue < 0.5
    ? interpolateColor(startRGB, middleRGB, normalizedValue * 2) // Red -> Blue
    : interpolateColor(middleRGB, endRGB, (normalizedValue - 0.5) * 2); // Blue -> Green

  return `rgb(${rgb.join(", ")})`;
};

const closeModalOnClickOutside = (event) => {
  const modal = document.getElementById("climateModal");
  if (modal && !modal.contains(event.target) && !modal.classList.contains("opacity-0")) {
    closeModal();
  }
};

let timeoutId;
const toggleCommentField = (slider) => {
  clearTimeout(timeoutId);

  timeoutId = setTimeout(() => {
    const sliderContainer = slider.closest(".slider-container");
    const commentField = sliderContainer.querySelector(".climate-comment-field");
    const value = parseInt(slider.value, 10);

    if (value < 0 && commentField.classList.contains("opacity-0")) {
      showCommentField(sliderContainer);
    } else if (value >= 0 && !commentField.classList.contains("opacity-0")) {
      hideCommentField(sliderContainer);
    }

    // We always hide the leave comment link when toggling the comment field.
    // Because if the score is negative, we show the comment field.
    // If the score is positive, we don't allow the user to leave a comment.
    hideLeaveCommentLink(sliderContainer);
  }, 150); // Delay to allow the slider to stop moving before triggering
};

const hideCommentField = (sliderContainer) => {
  const commentField = sliderContainer.querySelector(".climate-comment-field");

  setTimeout(function () {
    commentField.classList.add("opacity-0", "h-0", "overflow-hidden");
    commentField.classList.remove("opacity-100", "h-auto");
  }, 100);
};

const showCommentField = (sliderContainer) => {
  const commentField = sliderContainer.querySelector(".climate-comment-field");

  setTimeout(function () {
    commentField.classList.add("opacity-100", "h-auto");
    commentField.classList.remove("h-0", "opacity-0", "overflow-hidden");
  }, 100);
};

const showLeaveCommentLink = (sliderContainer) => {
  const leaveCommentLink = sliderContainer.querySelector('.leave-comment-link');

  setTimeout(function () {
    leaveCommentLink.classList.add("opacity-100", "h-auto");
    leaveCommentLink.classList.remove("h-0", "opacity-0", "overflow-hidden");
  }, 100);
};

const hideLeaveCommentLink = (sliderContainer) => {
  const leaveCommentLink = sliderContainer.querySelector('.leave-comment-link');

  setTimeout(function () {
    leaveCommentLink.classList.add("opacity-0", "h-0", "overflow-hidden");
    leaveCommentLink.classList.remove("opacity-100", "h-auto");
  }, 100);
};
