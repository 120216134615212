import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="expandable-content"
export default class extends Controller {
  static targets = ["content", "indicatorIcon"];
  static values = { expandedIndicatorIconClasses: String };

  toggle(event) {
    const row = event.currentTarget;
    const content = row.nextElementSibling;

    if (content && content.classList.contains("expandable-content")) {
      content.classList.toggle("hidden");
    }

    if (this.indicatorIconTarget) {
      this.expandedIndicatorIconClassesValue.split(' ').forEach((className) => {
        this.indicatorIconTarget.classList.toggle(className);
      });
    }
  }
}
